import React from 'react';
import PropTypes from 'prop-types';
// import CheckList from '../../CheckList/CheckList';

const ChecklistComponent = ({ data, heading }) => {
  return (
    <div className="checklist-component mb-[48px]">
      <h2 className="font-body text-[20px] leading-[30px] md:text-4md font-semibold mb-[16px] md:mb-[24px] text-cf-hero">
        {heading}
      </h2>
      <div className="flex flex-col md:flex-row flex-nowrap w-full">
        <div className="w-full ">
          <ul className="p-0 list-none max-w-full mb-[16px] md:mb-[0] flex flex-wrap">
            {data.map(({ id, text, url, alt }) => (
              <li
                className="mb-[16px] md:mb-[12px] w-full md:w-1/2 last:mb-0 flex items-center"
                key={id}>
                <div className="w-[20px] h-[20px] flex justify-center items-center flex-shrink-0  mr-[8px]  ">
                  <img src={url} alt={alt} />
                </div>
                <h3 className="font-body !m-0 text-[14px] leading-[21px] md:leading-[24px]">
                  {text}
                </h3>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

ChecklistComponent.propTypes = {
  heading: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ),
};

ChecklistComponent.defaultProps = {
  data: [],
  heading: 'Using Payment Gateway',
};

export default ChecklistComponent;
