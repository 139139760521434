import React from 'react';

const availAccessData = [
  {
    id: 0,
    url: '/img/pricing/dedicated-manager.png',
    alt: 'Dedicated Account Manager',
    content: (
      <>
        <span className="text-white font-normal md:font-semibold">
          Dedicated Account Manager
        </span>{' '}
        with fortnightly check- ins to provide support and discuss business
        health
      </>
    ),
  },
  {
    id: 1,
    url: '/img/pricing/priority-business-support.png',
    alt: 'Priority business support',
    content: (
      <>
        <span className="text-white font-normal md:font-semibold">
          Priority business support
        </span>{' '}
        with an assured response time of 30 mins
      </>
    ),
  },
  {
    id: 2,
    url: '/img/pricing/faster-resolution.png',
    alt: 'faster resolution',
    content: (
      <>
        <span className="text-white font-normal md:font-semibold">
          20% faster resolution
        </span>{' '}
        to dispute management
      </>
    ),
  },
];

const AvailAccess = () => {
  return (
    <div className="bg-cf-dark relative py-[40px] md:py-[48px] px-[16px] md:px-[40px] rounded-[4px] mb-[48px]">
      <div className="flex items-center justify-between flex-col md:flex-row">
        <div className="max-w-full md:max-w-[283px] mb-[20px] md:mb-0">
          <h2 className="text-white font-body text-3md font-semibold mb-[8px]">
            Avail exclusive access to our premium services
          </h2>
          <p className="text-cf-cold-purple text-[1rem] md:text-[14px] leading-[24px]">
            with an annual maintenance charge*
          </p>
        </div>
        <div className="max-w-full md:max-w-[410px]">
          {availAccessData.map(({ id, url, alt, content }) => (
            <div className="flex items-center mb-[16px] md:mb-[24px]" key={id}>
              <div className="w-[32px] mr-[13px] md:mr-[16px] flex-shrink-0 ">
                <img src={url} alt={alt} />
              </div>
              <p className="text-cf-cold-purple md:text-[14px] leading-[21px] md:leading-[24px] ">
                {content}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailAccess;
