import React from 'react';

const ValueAddedServices = () => {
  return (
    <section className="my-[48px]">
      <div className="py-[40px] md:py-[48px] px-[17px] md:px-[32px] bg-cf-primary rounded relative overflow-hidden custom-payment-form">
        <div className="flex justify-between items-center flex-col-reverse md:flex-row">
          <div className="max-w-[347px]">
            <img src="/img/pricing/vas-bg.png" alt="value added services" />
          </div>
          <div className="w-full md:w-1/2 mb-[28px] md:mb-0">
            <div className="text-white  text-[20px] leading-[30px] font-semibold  mb-[8px]">
              Value-added Services at no cost!
            </div>
            <div className="max-w-full md:max-w-[790px] text-md text-white">
              Use our Payment Links, Payment Forms, and softPOS products for
              free - only pay for our Payment Gateway! Generate links and forms
              or use our softPOS app to collect payments with ease
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValueAddedServices;
