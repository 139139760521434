import React from 'react';
import AutoCollectContent from '../components/PricingComponents/AutoCollectContent';
import CashgramContent from '../components/PricingComponents/CashgramContent';
import EasySplitContent from '../components/PricingComponents/EasySplitContent';
import InstantSettlementsContent from '../components/PricingComponents/InstantSettlementsContent';
// import PaymentGatewayContent from '../components/PricingComponents/PaymentGatewayContent';
import PayoutsContent from '../components/PricingComponents/PayoutsContent';
import AvailAccess from '../components/PricingComponents/SubComponents/AvailAccess';
import ChecklistComponent from '../components/PricingComponents/SubComponents/ChecklistComponent';
import PricingAccordion from '../components/PricingComponents/SubComponents/PricingAccordion';
import ValueAddedServices from '../components/PricingComponents/SubComponents/ValueAddedServices';
import SubscriptionsContent from '../components/PricingComponents/SubscriptionsContent';

export const paymentGatewayChecklist = [
  {
    id: 0,
    text: <>Next day settlement</>,
    url: '/img/pricing/icons/icon1.svg',
    alt: 'icon1',
  },
  {
    id: 1,
    text: <>Paperless and same day onboarding</>,
    url: '/img/pricing/icons/icon2.svg',
    alt: 'icon2',
  },
  {
    id: 2,
    text: <>International payments in 30 currencies</>,
    url: '/img/pricing/icons/icon3.svg',
    alt: 'icon3',
  },
  {
    id: 3,
    text: <>Easy to use Dashboard</>,
    url: '/img/pricing/icons/icon4.svg',
    alt: 'icon4',
  },
  {
    id: 4,
    text: <>Instant Refunds</>,
    url: '/img/pricing/icons/icon5.svg',
    alt: 'icon5',
  },
  {
    id: 5,
    text: <>E-commerce Plugins</>,
    url: '/img/pricing/icons/icon6.svg',
    alt: 'icon6',
  },
  {
    id: 6,
    text: <>Dedicated account manager</>,
    url: '/img/pricing/icons/icon7.svg',
    alt: 'icon7',
  },
  {
    id: 7,
    text: <>Developer friendly integration kits</>,
    url: '/img/pricing/icons/icon8.svg',
    alt: 'icon8',
  },
  {
    id: 8,
    text: <>Now Live on Juspay. Connect Today</>,
    url: '/img/pricing/icons/icon9.svg',
    alt: 'icon9',
  },
];

export const lowestPricingList = [
  {
    id: 0,
    text: <>No setup, maintenance or any other hidden fees</>,
  },
  {
    id: 1,
    text: <>Pay only for actual transactions</>,
  },
  {
    id: 2,
    text: <>Real-time transaction fees reporting</>,
  },
  {
    id: 3,
    text: <>Paperless and same day onboarding</>,
  },
];

export const paymentModesInclude = [
  {
    id: 0,
    icon: '/img/pricing/logo/visa.svg',
    name: 'Visa',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/mastercard.svg',
    name: 'Mastercard',
  },
  {
    id: 2,
    icon: '/img/pricing/logo/maestro.svg',
    name: 'Maestro',
  },
  {
    id: 3,
    icon: '/img/pricing/logo/icici.svg',
    name: 'ICICI',
  },
  {
    id: 4,
    icon: '/img/pricing/logo/amazonpay.svg',
    name: 'Amazon Pay',
  },
  {
    id: 5,
    icon: '/img/pricing/logo/airtel-money.svg',
    name: 'Airtel Money',
  },
  {
    id: 6,
    icon: '/img/pricing/logo/jio-money.svg',
    name: 'Jio Money',
  },
  {
    id: 7,
    icon: '/img/pricing/logo/phonepe.svg',
    name: 'PhonePe',
  },
  {
    id: 8,
    icon: '/img/pricing/logo/netbanking.svg',
    name: 'Net Banking',
  },
];

export const paymentModesIncludeFold2 = [
  {
    id: 0,
    icon: '/img/pricing/logo/upi.svg',
    name: 'UPI',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/rupay.svg',
    name: 'RuPay',
  },
];

export const creditCardEmi = [
  {
    id: 0,
    icon: '/img/pricing/logo/hdfc.svg',
    name: 'HDFC',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/kotak.svg',
    name: 'Kotak',
  },
  {
    id: 2,
    icon: '/img/pricing/logo/icici.svg',
    name: 'ICICI',
  },
  {
    id: 3,
    icon: '/img/pricing/logo/sbi.svg',
    name: 'SBI',
  },
  {
    id: 4,
    icon: '/img/pricing/logo/baroda.svg',
    name: 'Bank of Baroda',
  },
  {
    id: 5,
    icon: '/img/pricing/logo/standard.svg',
    name: 'Standard Chartered',
  },
  {
    id: 6,
    icon: '/img/pricing/logo/axis.svg',
    name: 'Axis',
  },
  {
    id: 7,
    icon: '/img/pricing/logo/other.svg',
    name: 'Other Major Banks',
  },
];

export const debitCardEmi = [
  {
    id: 0,
    icon: '/img/pricing/logo/hdfc.svg',
    name: 'HDFC Bank',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/other.svg',
    name: 'Coming Soon',
  },
];

export const cardlessEmi = [
  {
    id: 0,
    icon: '/img/pricing/logo/hdfc.svg',
    name: 'HDFC Bank',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/icici.svg',
    name: 'ICICI Bank',
  },
  {
    id: 2,
    icon: '/img/pricing/logo/kotak.svg',
    name: 'Kotak Bank',
  },
  {
    id: 3,
    icon: '/img/pricing/logo/idfc.svg',
    name: 'IDFC First Bank',
  },
  {
    id: 4,
    icon: '/img/pricing/logo/federal.svg',
    name: 'Federal Bank',
  },
  {
    id: 5,
    icon: '/img/pricing/logo/zest.svg',
    name: 'Zest Money',
  },
  {
    id: 6,
    icon: '/img/pricing/logo/kreditbee.svg',
    name: 'KreditBee',
  },
];

export const payLater = [
  {
    id: 0,
    icon: '/img/pricing/logo/lazypay.svg',
    name: 'LazyPay',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/ola-money.svg',
    name: 'Ola Money Postpaid',
  },
  {
    id: 2,
    icon: '/img/pricing/logo/epay-later.svg',
    name: 'ePay Later',
  },
  {
    id: 3,
    icon: '/img/pricing/logo/hdfc.svg',
    name: 'HDFC via Flexmoney',
  },
  {
    id: 4,
    icon: '/img/pricing/logo/kotak.svg',
    name: 'Kotak via Flexmoney',
  },
  {
    id: 5,
    icon: '/img/pricing/logo/simpl.svg',
    name: 'Simpl',
  },
  {
    id: 6,
    icon: '/img/pricing/logo/freecharge.svg',
    name: 'Freecharge PayLater',
  },
  {
    id: 7,
    icon: '/img/pricing/logo/zest.svg',
    name: 'Zest Money PayLater',
  },
];

export const internationalCardsFold1 = [
  {
    id: 0,
    icon: '/img/pricing/logo/visa.svg',
    name: 'Visa',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/mastercard.svg',
    name: 'Mastercard',
  },
];
export const internationalCardsFold2 = [
  {
    id: 0,
    icon: '/img/pricing/logo/paypal.svg',
    name: 'Paypal',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/paytm.svg',
    name: 'Paytm',
  },
];
export const internationalCardsFold3 = [
  {
    id: 0,
    icon: '/img/pricing/logo/visa.svg',
    name: 'Diners Club',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/amex.svg',
    name: 'American Express',
  },
];

export const subAccordionData = [
  {
    title: 'Payment Gateway',
    desc:
      'Accept payments in Indian Rupees (INR) and other 100+ foreign currencies with minimal integration effort and go live in no time.',
    icon: '/img/icons/payment-gateway-2.svg',
    content: (
      <>
        <div className="mb-[16px]">
          <PricingAccordion
            data={[
              {
                heading: 'Payment Modes include',
                content: (
                  <>
                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {paymentModesInclude.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px] first:mb-[28px] mr-[23px]">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <p className="line-through font-semibold text-[14px] leading-[24px] text-cf-cold-purple">
                          2%
                        </p>
                        <h3 className="font-body font-semibold text-[24px] leading-[33px] text-cf-primary">
                          1.90%
                        </h3>
                        <p className="font-medium text-[14px] leading-[18px] text-cf-faded-black">
                          per transaction
                        </p>
                      </div>
                    </div>
                    <hr className="my-[32px]" />
                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {paymentModesIncludeFold2.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px]  mr-[23px]">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <h3 className="font-body font-semibold text-[24px] leading-[33px] text-cf-primary">
                          0%*
                        </h3>
                        <p className="font-medium text-[14px] leading-[18px] text-cf-faded-black">
                          per transaction
                        </p>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="mb-[16px]">
          <PricingAccordion
            data={[
              {
                heading: 'EMI',
                content: (
                  <>
                    <p className="font-semibold text-[14px] leading-[21px] text-cf-hero mb-[24px]">
                      Credit Card EMI
                    </p>
                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {creditCardEmi.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px] first:mb-[28px] mr-[23px] text-center">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <h3 className="font-body font-semibold text-[24px] leading-[33px] text-cf-primary">
                          TDR + .25%
                        </h3>
                        <p className="font-medium text-[14px] leading-[18px] text-cf-faded-black">
                          per transaction
                        </p>
                      </div>
                    </div>
                    <hr className="my-[32px]" />
                    <p className="font-semibold text-[14px] leading-[21px] text-cf-hero mb-[24px]">
                      Debit Card EMI
                    </p>
                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {debitCardEmi.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px]  mr-[23px] text-center">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <h3 className="font-body font-semibold text-[24px] leading-[33px] text-cf-primary">
                          1.5%
                        </h3>
                        <p className="font-medium text-[14px] leading-[18px] text-cf-faded-black">
                          per transaction
                        </p>
                      </div>
                    </div>
                    <hr className="my-[32px]" />
                    <p className="font-semibold text-[14px] leading-[21px] text-cf-hero mb-[24px]">
                      Cardless EMI
                    </p>
                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {cardlessEmi.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px] first:mb-[28px] mr-[23px] text-center">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <h3 className="font-body font-semibold text-[24px] leading-[33px] text-cf-primary">
                          1.90%
                        </h3>
                        <p className="font-medium text-[14px] leading-[18px] text-cf-faded-black">
                          per transaction
                        </p>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="mb-[16px]">
          <PricingAccordion
            data={[
              {
                heading: 'PayLater',
                content: (
                  <>
                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {payLater.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px] first:mb-[56px] mr-[23px] text-center">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <p className="line-through font-semibold text-[14px] leading-[24px] text-cf-cold-purple">
                          2.5%
                        </p>
                        <h3 className="font-body font-semibold text-[24px] leading-[33px] text-cf-primary">
                          2.20%
                        </h3>
                        <p className="font-medium text-[14px] leading-[18px] text-cf-faded-black">
                          per transaction
                        </p>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="mb-[16px]">
          <PricingAccordion
            data={[
              {
                heading: 'International Cards',
                content: (
                  <>
                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {internationalCardsFold1.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px]  mr-[23px] text-center">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <h3 className="font-body font-semibold text-[24px] leading-[33px] text-cf-primary">
                          3.5% + ₹7
                        </h3>
                        <p className="font-medium text-[14px] leading-[18px] text-cf-faded-black">
                          per transaction
                        </p>
                      </div>
                    </div>
                    <hr className="my-[32px]" />

                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {internationalCardsFold2.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px]  mr-[23px] text-center">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <h3 className="font-body font-semibold text-[16px] leading-[24px] text-cf-primary">
                          Standard payment gateway
                          <br />
                          charges applicable
                        </h3>
                      </div>
                    </div>
                    <hr className="my-[32px]" />

                    <div className="flex items-center justify-between mb-[22px]">
                      <div className="flex w-[585px] border-r border-dashed border-l-cf-light-cold-purple">
                        <div className="flex-grow flex flex-wrap ">
                          {internationalCardsFold3.map((item) => (
                            <div
                              key={item.id}
                              className="flex flex-col items-center space-y-[8px] w-[90px] first:mb-[28px] mr-[23px] text-center">
                              <img
                                alt={item.name}
                                height={48}
                                width={48}
                                src={item.icon}
                              />
                              <p className="text-2.5sm font-normal">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col text-end ">
                        <h3 className="font-body font-semibold text-[24px] leading-[33px] text-cf-primary">
                          2.95%
                        </h3>
                        <p className="font-medium text-[14px] leading-[18px] text-cf-faded-black">
                          per transaction
                        </p>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
        <ValueAddedServices />
        <AvailAccess />
        <ChecklistComponent
          heading="Already using payment gateway?"
          data={paymentGatewayChecklist}
        />
      </>
    ),
  },
  {
    title: 'Subscriptions',
    desc:
      'Collect repeat payments easily with the most advanced recurring payments solution. Convert and retain more customers through higher success rates on mandate creation and auto-debit.',
    icon: '/img/icons/subscriptions.svg',
    content: <SubscriptionsContent />,
  },
  {
    title: 'Auto Collect',
    desc:
      'Accept payments directly in your bank account via UPI, NEFT, IMPS and RTGS. Match each payment to a customer or an order and reconcile automatically.',
    icon: '/img/icons/auto-collect-3.svg',
    content: <AutoCollectContent />,
  },
  {
    title: 'Easy Split',
    desc:
      'With Easy Split, collect payments from customers via any digital method, deduct commissions, and split payments to vendors, partners & your own bank accounts.',
    icon: '/img/icons/easy-split.svg',
    content: <EasySplitContent />,
  },
  {
    title: 'Instant Settlements',
    desc:
      'Receive your Payment Gateway settlements within seconds. Opt from a wide range of settlement options and never run out of funds.',
    icon: '/img/icons/instant-settlements-new.svg',
    content: <InstantSettlementsContent />,
  },
  {
    title: 'Payouts',
    desc:
      'Pay vendors, do instant customer refunds, disburse loans, etc instantly 24x7, even on a bank holiday.',
    icon: '/img/icons/payouts-green.svg',
    content: <PayoutsContent />,
  },
  {
    title: 'Cashgram',
    desc:
      'Payout links to send money without collecting receiver bank account details or UPI details.',
    icon: '/img/icons/cashgram.svg',
    content: <CashgramContent />,
  },
];

export const accordionData = [
  {
    key: 0,
    heading: 'Collect Payments',
    content: (
      <>
        {subAccordionData.slice(0, 5).map((data) => {
          return <p>{data.title}</p>;
        })}
      </>
    ),
  },
  {
    key: 1,
    heading: 'Make Payouts',
    content: (
      <>
        {subAccordionData.slice(5, 7).map((data) => {
          return <p>{data.title}</p>;
        })}
      </>
    ),
  },
];

export const exploreData = [
  {
    id: 0,
    title: 'Instant Settlements',
    body:
      'Get access to your payment gateway collection within 15 minutes even on bank holidays. Say goodbye to cash flow problems.',
    url: 'https://www.cashfree.com/payment-links',
    iconname: '/img/pricing/settlements.svg',
  },
  {
    id: 1,
    title: 'Buy Now Pay Later',
    body:
      'Provide 30+ flexible payments on your checkout and increase your average order value by upto 40%.',
    url: 'https://www.cashfree.com/recurring-payment',
    iconname: '/img/pricing/buynow-paylater.svg',
  },
  {
    id: 2,
    title: 'Easy Split',
    body: 'Split vendor commissions and manage marketplace settlements.',
    url: 'https://www.cashfree.com/easy-split/split-payment-gateway',
    iconname: '/img/pricing/easy-split.svg',
  },
  {
    id: 3,
    title: 'Payouts',
    body:
      'Make payouts to any bank account / UPI ID / card/ AmazonPay / Paytm instantly even on a bank holiday.',
    url: 'https://www.cashfree.com/auto-e-collect',
    iconname: '/img/pricing/payouts.svg',
  },
];

export const faqData = [
  {
    id: 0,
    q: 'What is Cashfree Payment Links and why should I use them?',
    a: (
      <>
        <p>
          Payment Links is the easiest way to receive payments from your
          customers for any goods or services, without taking them to any app or
          website. It doesn&apos;t even require any integration with a Payment
          Gateway.
        </p>
        <p>
          The Payment Link can be shared with the customer via different
          channels like WhatsApp, SMS, email etc., and upon clicking the link,
          your customers can pay using all available payment methods. Cashfree
          Payments will notify you when payment(s) are made using the link.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q: 'How do I start using Payment Links with Cashfree?',
    a: (
      <>
        <p>
          Simply sign up for Payment Links account, and Cashfree Payment Gateway
          will be automatically activated for your account.
        </p>
        <p>
          The Payment Links feature will be available inside the Payment Gateway
          product.
        </p>
        <p>Generate Payment Link in 3 easy steps:</p>
        <ul className="list-decimal">
          <li>
            Create a Payment Link via Payment Links dashboard or APIs. Customize
            the link to match with your brand by adding your brand color and
            logo.
          </li>
          <li>
            Add customer details and paste or embed link anywhere. Share it
            across with customers.
          </li>
          <li>
            Accept payments instantly. Get notified in real time about a
            successful payment.
          </li>
        </ul>
        <p>
          All payments collected via the payment links are automatically updated
          to in the reports and transactions module in Cashfree Payment Gateway.
          Know more in our{' '}
          <a
            href="https://cashfree.gitbook.io/payment-links/create-payment-link"
            className="link text-cf-green hover:text-cf-green">
            documentation.
          </a>
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'What information do I need to provide to create Payment Links?',
    a: (
      <>
        <p>
          To quickly request payment from your customer via Payment Link, you
          simply need to provide the customer phone or email information, and
          the amount and purpose for the link, and the link can be shared with
          the customer. While creating a Payment Link, you can enable the ‘Send
          SMS and Email’ checkboxes, and Cashfree Payments will send the link to
          the customer on the provided contact information.
        </p>
        <p>
          In addition to this, you can set the expiry for the link, and enable
          partial payments, if you want to enable the customer to make payment
          in parts.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'Can I generate Payment Links in bulk?',
    a: (
      <>
        <p>
          Yes, you can send Payment Links in bulk with our Batch feature. You
          can upload a XLSX or CSV file containing the list of customers’
          contact details, amount and purpose for the links and send the
          requests in bulk. All this within a matter of minutes!
        </p>
      </>
    ),
  },
  {
    id: 4,
    q: 'Can my customers make multiple partial payments on Payment Links?',
    a: (
      <p>
        Yes, you can enable Partial Payments and provide the minimum amount you
        wish to collect for a Payment Link, and the customer can choose to make
        multiple payments, between the minimum amount and the link amount.
        Cashfree Payments will notify you of all partial payments done.
      </p>
    ),
  },
  {
    id: 5,
    q: 'Are international payments supported on Payment Link?',
    a: (
      <>
        <p>
          Yes, it is possible to enable international payments on Cashfree
          Payment Links -- talk to us and help us understand your business for
          quick activation. If you have a Paypal account,{' '}
          <a
            href="https://blog.cashfree.com/paypal-integration/"
            className="link text-cf-green hover:text-cf-green">
            connect Paypal to Cashfree
          </a>{' '}
          and start offering Paypal as a checkout option on the payment page
          instantly.
        </p>
      </>
    ),
  },
  {
    id: 6,
    q: 'What are Orders?',
    a: (
      <>
        <p>
          Each time your customer opens a Payment Link and attempts payment, a
          new Order is created against that Link. This gives you the information
          on how many times the customer has visited the Link before completely
          paying the full amount. You can follow up with such customers and try
          to improve your conversion rate.
        </p>
      </>
    ),
  },
];

export const customPricingList = [
  {
    id: 0,
    text: 'Early access to new features',
  },
  {
    id: 1,
    text: 'Dedicated account manager',
  },
  {
    id: 2,
    text: 'Discount pricing',
  },
  {
    id: 3,
    text: 'Support over WhatsApp in addition to other channels',
  },
];
