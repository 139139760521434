import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import '../../Accordion/Accordion.scss';

function PricingAccordion({ data }) {
  const [isTabActive, setTabActive] = useState(
    new Array(data.length).fill(false),
  );
  const [ing, setIng] = useState(1);

  function onClick(index) {
    isTabActive[index] = !isTabActive[index];
    setIng(ing + 1);
    setTabActive(() => isTabActive);
    // console.log(isTabActive);
  }

  return (
    <div className="accordion-container space-y-[16px]">
      {data.map((d, i) => (
        <div className="tab text-left overflow-hidden w-full font-semibold bg-cf-light-grey">
          <button
            className="button-unstyled w-full flex flex-row justify-between text-2.5sm md:text-md accordion-heading font-semibold font-body text-cf-hero"
            type="button"
            onClick={() => onClick(i)}>
            {d.heading}
            {/* {console.log(isTabActive[i])} */}
            <Icon
              name="chevron-current"
              width="11px"
              className={`self-center flex-shrink-0 ${
                isTabActive[i] ? 'text-cf-icon-light' : ''
              }`}
              rotate={isTabActive[i] ? '180deg' : '0deg'}
            />
          </button>
          <div
            className={`accordion-content !px-[40px] ${
              isTabActive[i] ? 'active' : ''
            }`}>
            {d.content}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PricingAccordion;
PricingAccordion.defaultProps = {
  data: [],
};
PricingAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
};
