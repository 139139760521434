import React from 'react';
import PropTypes from 'prop-types';

function PricingSubContent({
  title,
  icon,
  desc,
  primaryButton,
  primaryButtonLink,
  secondaryButton,
  secondaryButtonLink,
  content,
}) {
  return (
    <div>
      <div>
        <img src={icon} alt={title} className="mb-4" width={48} height={48} />
        <h1 className="mb-3 md:mb-2 text-4md md:text-shl">{title}</h1>
        <p className="text-[15px] leading-[21px] mb-8 max-w-2xl">{desc}</p>
        <div className="mb-12 md:flex">
          <a
            className="button button-green w-full mb-3 md:w-auto md:mb-0 md:mr-4"
            href={primaryButtonLink}>
            {primaryButton} &nbsp;
            <span className="chevron" />
          </a>
          <a
            className="button button-light w-full mb-3 md:w-auto md:mb-0 text-cf-hero border-cf-stroke"
            href={secondaryButtonLink}>
            {secondaryButton} &nbsp;
            <span className="chevron" />
          </a>
        </div>
      </div>
      {content}
    </div>
  );
}

PricingSubContent.defaultProps = {
  title: '',
  icon: '',
  desc: '',
  primaryButton: 'Create Account',
  primaryButtonLink: '',
  secondaryButton: 'Contact Sales',
  secondaryButtonLink: '',
  content: '',
};
PricingSubContent.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  desc: PropTypes.string,
  primaryButton: PropTypes.string,
  primaryButtonLink: PropTypes.string,
  secondaryButton: PropTypes.string,
  secondaryButtonLink: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
export default PricingSubContent;
