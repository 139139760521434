import React, { useState } from 'react'; // Fragment ,
import CheckList from '../components/CheckList/CheckList';
import FAQ from '../components/FAQ';
import GetStarted from '../components/GetStarted';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Accordion from '../components/Accordion/Accordion';

import {
  customPricingList,
  lowestPricingList,
  faqData,
  accordionData,
  subAccordionData,
} from '../content/pricing';
import '../styles/pricing-new.scss';
import PricingSubContent from '../components/PricingSubContent';

function PricingNew(rest) {
  // const container = useRef();

  function generateAccordion(subContent, setSubContent) {
    accordionData[0].content = (
      <div>
        {subAccordionData.slice(0, 5).map((data, index) => {
          return (
            <button
              type="button"
              className={`mb-2 px-4 flex items-center w-[200px] rounded ${
                subContent === index ? 'bg-cf-primary text-white' : ''
              }`}
              onClick={() => {
                setSubContent(index);
              }}>
              <img src={data.icon} alt={data.title} width={26} height={26} />
              <p className="ml-2 my-2 text-2.5sm font-semibold">{data.title}</p>
            </button>
          );
        })}
      </div>
    );
    accordionData[1].content = (
      <div>
        {subAccordionData.slice(5, 7).map((data, index) => {
          return (
            <button
              type="button"
              className={`mb-2 px-4 flex items-center w-[200px] rounded ${
                subContent === index + 5 ? 'bg-cf-primary text-white' : ''
              }`}
              onClick={() => {
                setSubContent(index + 5);
              }}>
              <img src={data.icon} alt={data.title} width={26} height={26} />
              <p className="ml-2 my-2 text-2.5sm font-semibold">{data.title}</p>
            </button>
          );
        })}
      </div>
    );
  }
  const [subContent, setSubContent] = useState(0);
  generateAccordion(subContent, setSubContent);
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=NBFC%20lending%20solution&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=NBFC%20lending%20solution&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Digital lending solutions for NBFCs & Fintechs - Cashfree Payments"
        description="Create Escrow accounts, operate through our digital lending solutions' APIs for loan disbursals, repayments & co-lending, in compliance with RBI's digital lending guidelines"
        url="http://cashfree.com/nbfc-digital-lending-solutions/"
        keywords={[
          'Digital lending solution',
          'escrow',
          'NBFC',
          'Payouts Lending',
          'NBFC Solutions',
          'Lending API',
          'Payment Collection Platform',
          'NBFC Lending',
          'Loan Repayment API',
          'Loan API Provider in India',
          'Repayment Collection',
          'Money lending API',
          'Loan Solutions',
          'Lending Solutions',
          'Loan management system',
          'Digital lending platform',
          'NBFC Business loan',
          'NBFC Instant Loan',
        ]}
      />
      <section className="pb-0 md:pb-[145px] pt-[110px] md:pt-[175px] bg-cf-dark text-white relative pricing-hero overflow-hidden min-h-screen">
        <div className=" container position-relative z-10">
          <div className="flex flex-wrap md:flex-nowrap justify-start md:justify-between lg:w-[100%]">
            <div className="w-full md:w-1/2 pb-0 lg:self-center">
              <div className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Pricing
              </div>
              <div className="mb-3 md:mb-5 text-xl md:text-2xl font-semibold max-w-[600px] font-heading">
                Lowest <br className="hidden-mobile" />
                payment gateway <br className="hidden-mobile" />
                charges in India
              </div>
            </div>
            <div className=" md:w-4/6 sm:flex sm:items-center">
              <div className="bg-white px-[16px] md:px-[27px] py-[24px] md:pt-[30px] md:pb-[24px] sm:h-full rounded-l-[4px] flex flex-col justify-center relative dark-card-secondary overflow-hidden  border-cf-primary w-full md:w-2/4 duration-300 transition-all pricing-starts-at ">
                <div className="text-cf-dark firstdiv">
                  <p className="text-[12px] md:text-[14px] leading-[24px] text-cf-faded-black font-medium  mb-[8px] md:mb-[4px]">
                    Starts at
                  </p>
                  <p className="text-cf-primary md:text-[40px] md:leading-[48px] font-bold md:mb-[14px] pricing-percent">
                    1.90%*{' '}
                    <span className="ml-[10px] line-through text-md font-semibold text-cf-faded-black">
                      2.0%
                    </span>
                  </p>
                  <h3 className="font-body font-semibold  text-2.5sm mb-[20px] pricing-subtext ">
                    Access complete payments and banking platform at friendly
                    pricing
                  </h3>
                  <CheckList
                    data={lowestPricingList}
                    className="mb-0"
                    itemMargin="mb-[12px]"
                  />
                  <a
                    className="button button-green w-full mt-[20px] md:mt-[32px] md:w-auto mb-4 md:mb-0 mr-0 flex items-center justify-center pricing-btn-green"
                    href="#!">
                    Create Account &nbsp;
                    <span className="chevron" />
                  </a>
                </div>
              </div>
              <div className="bg-white bg-opacity-10 mt-12 mb-9 sm:my-0 w-full md:w-2/4 px-[16px] md:px-[24px] py-[24px] md:pt-[17px] md:pb-[24px] sm:h-full rounded-r-[4px] flex flex-col justify-center relative dark-card-secondary overflow-hidden border-t-[7px] border-cf-primary duration-300 transition-all pricing-enterprises">
                <div className="text-cf-white">
                  <p className="text-[12px] md:text-[13px] leading-[20px] text-cf-cold-purple font-semibold uppercase mb-[8px] md:mb-[10px]">
                    Enterprises
                  </p>
                  <h3 className="font-body font-bold md:font-semibold text-[20px] leading-8">
                    Custom pricing
                    <br /> designed for enterprises
                  </h3>
                  <div className="checklist-wrapper my-[32px] md:my-[12px]  ">
                    {customPricingList.map(({ id, text }) => (
                      <div key={id} className="flex mb-[12px]">
                        <span className="mt-0.5 w-5 h-5 flex justify-center items-center rounded-full mr-[10px] bg-[#ffffff40]">
                          <Icon
                            name="tick"
                            width="9.21px"
                            height="6.29px"
                            fill="#fff"
                          />
                        </span>
                        <p className="flex-1 m-0 text-[14px] md:text-[15px] leading-[21px] md:leading-[24px] ">
                          {text}
                        </p>
                      </div>
                    ))}
                  </div>
                  <p className="text-tiny text-cf-cold-purple ">
                    Get in touch with our sales team to explore the right
                    products for your payment needs and get custom pricing.
                  </p>
                  <a
                    className="button button-outline w-full mt-[20px] md:mt-[12px] md:w-auto mb-4 md:mb-0 mr-0 flex items-center justify-center"
                    href="#!">
                    Contact Sales &nbsp;
                    <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-cf-light-grey md:flex md:px-[40px] lg:px-[91px]">
          <div className="pt-[120px] border-r border-cf-stroke">
            <Accordion
              data={accordionData}
              className="root md:w-[260px] lg:[300px]"
              buttonClassName="!px-0 !py-5"
              contentClassName="!pl-0"
              border
              colored
              defaultOpen
              arrow="chevron-current"
              arrowClassName="w-[72px] px-6"
            />
          </div>
          <div className=" mt-[48px] md:mt-24 ml-0 md:ml-10 mb-0 md:mb-[96px] w-full bg-white px-[16px] md:px-[48px] py-[48px] md:py-[40px]">
            <PricingSubContent
              title={subAccordionData[subContent].title}
              icon={subAccordionData[subContent].icon}
              desc={subAccordionData[subContent].desc}
              content={subAccordionData[subContent].content}
            />

            <div className=" general-faqs pricing-faqs">
              <FAQ
                open="0"
                data={faqData}
                supportLinkHref="https://www.cashfree.com/help/hc"
              />
            </div>
          </div>
        </div>
      </section>

      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=NBFC%20lending%20solution&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default PricingNew;
