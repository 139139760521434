import React from 'react';
import ChecklistComponent from './SubComponents/ChecklistComponent';
import PricingAccordion from './SubComponents/PricingAccordion';

function CashgramContent() {
  return (
    <div>
      <PricingAccordion
        data={[
          {
            heading: 'Basic',
            content: (
              <div>
                <div>Hello</div>
              </div>
            ),
          },
          {
            heading: 'Transfer Charges',
            content: (
              <div className=" overflow-x-auto">
                <table className="table w-full">
                  <tr className="mt-4">
                    <th className="w-1/3 text-2sm font-semibold whitespace-nowrap">
                      IMPS
                    </th>
                    <th className="w-1/3 text-2sm font-semibold whitespace-nowrap">
                      NEFT
                    </th>
                    <th className="w-1/3 text-2sm font-semibold whitespace-nowrap">
                      UPI
                    </th>
                    <th className="text-2sm font-semibold whitespace-nowrap">
                      Transaction Amount
                    </th>
                  </tr>
                  <tr>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 2.50
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 1.75
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 3.00
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      <span className="text-[15px] font-semibold text-cf-faded-black">
                        up to{' '}
                      </span>
                      ₹1,000
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 5.00
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 1.75
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 6.00
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹1,000
                      <span className="text-[15px] text-cf-faded-black">
                        {' '}
                        to{' '}
                      </span>
                      ₹10,000
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 10.00
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 1.75
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      ₹ 10.00
                    </td>
                    <td className="pt-4 text-cf-primary text-md whitespace-nowrap">
                      <span className="text-[15px] font-semibold text-cf-faded-black">
                        Above{' '}
                      </span>
                      ₹10,000
                    </td>
                  </tr>
                </table>
              </div>
            ),
          },
          {
            heading: 'Cards',
            content: (
              <div className="flex">
                <div className="flex-grow flex py-5 sm:p-5 space-x-[14px] sm:space-x-[30px]">
                  <div className="flex flex-col items-center space-y-[8px]">
                    <img
                      alt="Visa"
                      height={48}
                      width={48}
                      src="/img/pricing/visa.svg"
                    />
                    <p className="text-2.5sm font-normal">Visa</p>
                  </div>
                  <div className="flex flex-col items-center space-y-[8px]">
                    <img
                      alt="Mastercard"
                      height={48}
                      width={48}
                      src="/img/pricing/mastercard.svg"
                    />
                    <p className="text-2.5sm font-normal">Mastercard</p>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-end pl-10 border-l border-dashed border-l-cf-light-cold-purple">
                  <p className="text-cf-primary text-4md mb-1">₹10</p>
                  <p className="font-medium text-2.5sm text-cf-faded-black">
                    per transaction
                  </p>
                </div>
              </div>
            ),
          },
          {
            heading: 'Wallets',
            content: (
              <div>
                <div className="flex pb-8">
                  <div className="flex-grow flex py-5 sm:p-5">
                    <div className="flex flex-col items-center space-y-[8px]">
                      <img
                        alt="Amazon Pay"
                        height={48}
                        width={48}
                        src="/img/pricing/amazonpay.svg"
                      />
                      <p className="text-2.5sm font-normal w-24 text-center">
                        Amazon Pay
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-end pl-10 border-l border-dashed border-l-cf-light-cold-purple">
                    <p className="text-cf-primary text-4md w-[190px] text-right mb-1">
                      Free
                    </p>
                  </div>
                </div>
                <div className="flex pt-8">
                  <div className="flex-grow flex py-5 sm:p-5">
                    <div className="flex flex-col items-center space-y-[8px]">
                      <img
                        alt="Paytm"
                        height={48}
                        width={48}
                        src="/img/pricing/paytm.svg"
                      />
                      <p className="text-2.5sm font-normal w-24 text-center">
                        Paytm
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-end pl-10 border-l border-dashed border-l-cf-light-cold-purple">
                    <p className="text-cf-primary text-md w-[190px] text-right mb-1">
                      As applicable on your Paytm business account
                    </p>
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
      <ChecklistComponent
        heading="Tailored made for Indian business"
        data={[
          {
            id: 0,
            text: <>No setup, maintenance or any other hidden fees</>,
          },
          {
            id: 1,
            text: <>Pay only for actual transactions</>,
          },
          {
            id: 2,
            text: <>Real time transaction fees reporting</>,
          },
          {
            id: 3,
            text: <>Paperless and same day onboarding</>,
          },
        ]}
      />
    </div>
  );
}

CashgramContent.defaultProps = {};

CashgramContent.propTypes = {};
export default CashgramContent;
